import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { WorksPage } from "../components/pageLayout/WorksPage";

export const works = graphql`
  query WorkPageQuery {
    works: allSanityWork(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              id
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const Works = ({ data }) => {
  const { works } = data;
  console.log(data);
  // console.log(works);
  return (
    <>
      <Seo title="Liz McClymont" />
      <p>Liz McClymont is an illustrator and designer working in the UK.</p>
      <WorksPage works={works.edges} />
    </>
  );
};

export default Works;
